<template>
  <section class="products">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="banner"
        :src="banner"
        class="ctk-top-banner"
        >
      </v-img>
    </v-flex>
    <v-container  grid-list-md text-center id="news_top" style="margin-bottom:10px">
      <div style="max-width:600px; text-align: center; margin: 0 auto;">
        <v-layout wrap text-left>
          <v-flex xs12 sm12 md12 lg12>
            <v-carousel
              cycle hide-delimiter-background show-arrows-on-hover
              class="ctk-contain-slider"
              :height="sliderHeight"
            >
              <v-carousel-item
                :v-if="sliders"
                v-for="(slider, i) in sliders"
                :key="i"
                :src="slider.img"
              ></v-carousel-item>
            </v-carousel>
            <h3 :v-if="detail.title" style="text-align: left;margin-top: 20px; font-size: 20px;">{{detail.title}}</h3>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12>
            <p :v-if="detail.attr"  v-for="(attr, i) in detail.attr" :key="i" style="margin: 20px 0px;">
              <span style="color:#666; font-size:16px;">{{attr.attr_name}}:</span><br/>
              <span style="color:#333; font-size:20px;">{{attr.attr_value}}</span>
            </p>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 mt-4>
            <div  v-html="detail.content" class="ctk-news-content">
              {{detail.content}}
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 3,
    detail: { title: null, content: null, attr: null },
    banner: null,
    sliders: [],
    sliderHeight: 520
  }),
  created () {
    document.querySelector('#menu_cases').classList.add('v-btn--active')
    if (window.innerWidth < 600) {
      this.sliderHeight = 220
    } else if (window.innerWidth < 960) {
      this.sliderHeight = 360
    } else {
      this.sliderHeight = 520
    }
    this.getCase(this.$route.params.id)
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.banner = val.banner[this.pageId][0].img
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    getCase (id) {
      this.https.get('case', { id: id }).then(response => {
        if (response.code === 0) {
          this.detail = response.data
          this.sliders = response.data.imgs
        }
      })
    }
  }
}
</script>
<style>
  .ctk-contain-slider .v-image__image--cover {
    background-size: contain;
  }
</style>
